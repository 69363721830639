@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "./variables";

$custom-colors: (
        "primary": $primary,
        "warning": $warning,
        "dark": $dark,
);
$theme-colors: map-merge($theme-colors, $custom-colors);
@import "bootstrap/scss/bootstrap";

*{
  font-family: 'Poppins', serif;
  .hover:hover{
    cursor: pointer;
  }
}